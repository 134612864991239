import * as React from 'react'
import { graphql } from 'gatsby'
import i18next from '../i18n.js'

import BlogIndexTemplate from '../components/pageTemplates/blogIndexTemplate'

const BlogPage = ({ data, pageContext }) => {
  i18next.changeLanguage('en')
  var  {t} = i18next

  return (
    <BlogIndexTemplate title={t('pageTitle')}
                       categoryListTitle={t('blogCategories')} 
                      pageContext={pageContext} 
                      data={data} 
                      locale="en" />     
  )
} 

export const query = graphql`
query blogEn{
  allMdx(
    filter: {frontmatter: {date: {ne: "null"}, locale: {eq: "en"}}}
    sort: {fields: frontmatter___date, order: DESC}
  ) {
    nodes {
      frontmatter {
        draft
        date(formatString: "D. MMMM YYYY", locale: "en")
        title
        locale
        noindex
        hero_image {
          childImageSharp {
            gatsbyImageData(width: 300, height: 150)
          }
        }
      }
      id
      slug
      excerpt
    }
  }
  allAirtableCategories(filter: {data: {Locale: {eq: "en"}}}) {
    nodes {
      data {
        Name
        Slug
        Locale
      }
    }
  }
}

`
/**  locales: allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  } */
export default BlogPage